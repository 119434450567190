import { player, playerHeadline, playerTitle, playerWrapper, recipe } from './index.module.scss'

import AudioPlayer from '../../components/audio-player'
import Button from '../../components/button'
import Container from '../../components/container'
import Layout from '../../components/layout/index'
import { Link } from 'gatsby'
import PostHeader from '../../components/post/header'
import PostTile from '../../components/post/tile'
import React from "react"
import SectionsRenderer from '../../components/sections/renderer'
import Seo from '../../components/seo'
import { getField } from '../../helper.js'
import { graphql } from 'gatsby'
import { pathTo } from '../../routes'

function Players({ audioFiles=[] }) {
  if (audioFiles.length === 0) return null
  const showTitle = audioFiles.length > 1
  return audioFiles.map(file => {
    if (!file?.fileDe || file?.fileDe === undefined || file?.fileDe === null) return null
    const { serviceUrl, duration } = file?.fileDe
    return (
      <div key={file.id} className={player}>
        {showTitle && file?.title && <div className={playerTitle}>{file?.title}</div> }
        <AudioPlayer
          url={serviceUrl}
          duration={duration}
        />
      </div>
    )
  })
}

const CookbookPost = ({ data, pageContext: { locale='de', pathDe } }) => {
  const { cmsCookbookPost } = data
  const title = cmsCookbookPost.titleDe

  const localizedUrls = {
    de: pathDe,
    en: null
  }

  const visual = getField(cmsCookbookPost.visual, "file", locale)
  const visualMobile = getField(cmsCookbookPost.visualMobile, "file", locale)
  const visualMedium = getField(cmsCookbookPost.visualMedium, "file", locale)
  const visualLarge = getField(cmsCookbookPost.visualLarge, "file", locale)
  const audioFiles = cmsCookbookPost?.audioFiles ?? []

  return (
    <>
      <Seo
        locale={locale}
        localizedUrls={localizedUrls}
        title={title}
      />
      <Layout locale={locale} localizedUrls={localizedUrls}>
        <PostHeader
          title={title}
          visual={visual}
          visualMobile={visualMobile}
          visualMedium={visualMedium}
          visualLarge={visualLarge}
          locale={locale}
        />
        { audioFiles.length > 0 && (
          <div className={playerWrapper}>
            <Container>
              <div className={playerHeadline}>
                { audioFiles.length === 1 && (
                  <b>Die Geschichte hinter dem Rezept erzählen wir dir hier:</b>
                )}
              </div>
              <Players audioFiles={audioFiles}/>
            </Container>
          </div>
        )}
        <SectionsRenderer sections={cmsCookbookPost?.sections} locale={locale}/>
        { cmsCookbookPost?.recipe?.image?.fileDe?.gatsbyImageData && (
          <Container>
            <div className={recipe}>
              <PostTile
                post={cmsCookbookPost?.recipe}
                locale="de"
              />
              <Button
                label="Zum Rezept"
                class="w-full md:w-auto"
                to={pathTo({ ...cmsCookbookPost.recipe, locale })}
              />
            </div>
          </Container>
        )}
      </Layout>
    </>
  )
}


// cover
// title
// -> wie rezepte

export default CookbookPost

export const query = graphql`
  fragment CookbookPostFragment on CMSCookbookPost {
    __typename
    id
    titleDe
    ...CookbookPostHeaderImages
    sections {
      __typename
      ...Text
    }
    audioFiles {
      id
      title: titleDe
      fileDe {
        serviceUrl
        duration
      }
    }
    recipe {
      __typename
      id
      titleDe
      slugDe
      image: coverImage {
        fileDe {
          gatsbyImageData(
            height: 701
            placeholder: DOMINANT_COLOR
            quality: 55
            fit: "thumb"
            width: 1110
          )
        }
      }
    }
  }

  query CookbookPostQuery($id: String) {
    cmsCookbookPost(id: {eq: $id}) {
      ...CookbookPostFragment
    }
  }
`

import * as styles from './index.module.scss'

import { PauseIcon, PlayIcon } from '@radix-ui/react-icons'
import React, { useRef, useState } from "react"

import { Range } from 'react-range'

const AudioPlayer = ({ url, duration = "40:00" }) => {
  const player = useRef(null)
  const [playing, setPlaying] = useState(false)
  const [progress, setProgress] = useState(0)
  if (!url || !duration) return null

  const togglePlayer = () => {
    player.current.paused ? player.current.play() : player.current.pause()
    setPlaying(!player.current.paused)
  }

  const play = () => {
    player.current.play()
    onPlay()
  }

  const pause = () => {
    player.current.pause()
    onPause()
  }

  const onPlay = () => {
    setPlaying(true)
  }

  const onPause = () => {
    setPlaying(false)
  }

  const onSeek = (percentage) => {
    pause()
    player.current.currentTime = player.current.duration / 100 * percentage[0]
    setProgress(percentage[0])

    if (playing) {
      play()
    }
  }

  const Icon = playing ? PauseIcon : PlayIcon

  return (
    <>
      <audio
        src={url}
        ref={player}
        preload='none'
        controls={false}
        onTimeUpdate={() => setProgress(player.current.currentTime / player.current.duration * 100)}
        onPlay={onPlay}
        onPause={onPause}
      >
        <track kind="captions" />
      </audio>
      <div className={styles.wrapper}>
        <div className={styles.controls}>
          <div
            className={styles.toggle}
            onClick={togglePlayer}
            role='button'
            onKeyDown={togglePlayer}
            tabIndex={0}
          >
            <Icon />
          </div>
          <div className={styles.progress}>
            <div className={styles.barWrapper}>
              <Range
                step={.1}
                min={0}
                max={100}
                values={[progress]}
                onChange={onSeek}
                renderTrack={({ props, children }) => (
                  <div {...props}>
                    {children}
                    <div className={styles.bar} style={{ width: `${progress}%` }} />
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div {...props}>
                    <div className={styles.head} />
                  </div>
                )}
              />
            </div>
          </div>
          <div className={styles.time}>
            {duration.replace('00:', '')}
          </div>
        </div>
      </div>
    </>
  )
}

export default AudioPlayer
